'use strict'
const {noWEBP} = require('../helpers/imageServiceConstants')

/**
 * Global MUTABLE support object
 * For tests, override the getFeature function and not the object
 * @type {{isWEBP: {lossless: boolean, lossy: boolean, alpha: boolean, animation: boolean}, isObjectFitBrowser: boolean}}
 */
const globalFeaturesSupportObj = {
    /**
     * checks if the browser supports webp image type
     * @type {object<boolean>}
     */
    isWEBP: noWEBP,
    /**
     * checks if the browser supports 'objectFit'
     * @type {boolean}
     */
    isObjectFitBrowser: true
}

module.exports.getFeature = function (feature) {
    return globalFeaturesSupportObj[feature]
}
module.exports.setFeature = function (feature, value) {
    globalFeaturesSupportObj[feature] = value
}
