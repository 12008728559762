'use strict'

const utils = require('./utils')
const constants = require('./imageServiceConstants')
const {getPreferredImageQuality, roundToFixed} = require('./imageServiceUtils')


/**
 * returns image filters part of the image transform uri
 * @param {object}                  transformsObj    transform parts object
 * @param {ImageTransformOptions}   options
 */
function setTransformOptions(transformsObj, options) {
    options = options || {}
    // options - general
    transformsObj.quality = getQuality(transformsObj, options)
    transformsObj.progressive = getProgressive(options)
    transformsObj.watermark = getWatermark(options)
    // options - filters & adjustments
    transformsObj.unsharpMask = getUnsharpMask(transformsObj, options)
    transformsObj.filters = getFilters(options)
}

/**
 *
 * @param {ImageTransformOptions}   options
 * @returns {string}
 */
function getWatermark(options) {
    return options.watermark
}


/**
 * returns progressive if required
 * @param {ImageTransformOptions}   options
 *
 * @returns {boolean}
 */
function getProgressive(options) {
    return options.progressive !== false
}

/**
 * returns image filters part of the image transform uri
 * @param {object}                  transformsObj    transform parts object
 * @param {ImageTransformOptions}   options
 *
 * @returns {number}
 */
function getQuality(transformsObj, options) {
    const isWEBP_PNG = transformsObj.fileType === constants.fileType.PNG && transformsObj.isWEBPSupport
    const isJPG = transformsObj.fileType === constants.fileType.JPG
    const isQualitySupported = isJPG || isWEBP_PNG
    if (isQualitySupported) {
        const transformData = utils.last(transformsObj.parts)

        const defaultQuality = getPreferredImageQuality(transformData.width, transformData.height)
        let quality = options.quality && (options.quality >= 5 && options.quality <= 90) ? options.quality : defaultQuality
        //increase quality by 5 for webp images
        quality = isWEBP_PNG ? quality + 5 : quality
        return parseInt(quality, 10)
    }
    //quality not supported
    return 0
}

/**
 * returns the desired transformed image filters
 * @param {ImageTransformOptions}   options
 *
 * @returns {object}
 */
function getFilters(options) {
    const filterOptions = options.filters || {}
    const filters = {}


    // contrast
    if (isValidImageFilter(filterOptions[constants.imageFilters.CONTRAST], -100, 100)) {
        filters[constants.imageFilters.CONTRAST] = filterOptions[constants.imageFilters.CONTRAST]
    }

    // brightness
    if (isValidImageFilter(filterOptions[constants.imageFilters.BRIGHTNESS], -100, 100)) {
        filters[constants.imageFilters.BRIGHTNESS] = filterOptions[constants.imageFilters.BRIGHTNESS]
    }

    // saturation
    if (isValidImageFilter(filterOptions[constants.imageFilters.SATURATION], -100, 100)) {
        filters[constants.imageFilters.SATURATION] = filterOptions[constants.imageFilters.SATURATION]
    }

    // hue
    if (isValidImageFilter(filterOptions[constants.imageFilters.HUE], -180, 180)) {
        filters[constants.imageFilters.HUE] = filterOptions[constants.imageFilters.HUE]
    }

    // blur
    if (isValidImageFilter(filterOptions[constants.imageFilters.BLUR], 0, 100)) {
        filters[constants.imageFilters.BLUR] = filterOptions[constants.imageFilters.BLUR]
    }

    return filters
}

/**
 * indicates if requested filter value is valid
 * @param {number}  filterValue     filter's value
 * @param {number}  minValue        min range
 * @param {number}  maxValue        max range
 *
 * @returns {boolean}
 */
function isValidImageFilter(filterValue, minValue, maxValue) {
    // check if filter name and filter values range valid
    return !isNaN(filterValue) && typeof filterValue === 'number' && filterValue !== 0 && (filterValue >= minValue && filterValue <= maxValue)
}

/**
 * returns the desired transformed image unSharpMask values
 * @param {object}                  transformsObj    transform parts object
 * @param {ImageTransformOptions}   options
 *
 * @returns {object}
 */
function getUnsharpMask(transformsObj, options) {
    // construct usm values
    let usm

    // If options.unsharpMask is a valid value, use it
    if (isUSMValid(options.unsharpMask)) {
        usm = {
            radius: options.unsharpMask.radius,
            amount: options.unsharpMask.amount,
            threshold: options.unsharpMask.threshold
        }
    // if options.unsharpMask is not all zeros and not valid and usm should be used, use default
    } else if (!isZeroUSM(options.unsharpMask)) {
        if (isUSMNeeded(transformsObj)) {
            usm = constants.defaultUSM
        }
    }
    // If we got usm, change values to have trailing zeros (.00), else return undefined
    if (usm) {
        usm.radius = roundToFixed(usm.radius, 2)
        usm.amount = roundToFixed(usm.amount, 2)
        usm.threshold = roundToFixed(usm.threshold, 2)
    }

    return usm
}

/**
 * indicates if usm is needed
 * @param {object}      transformsObj   transform parts object
 *
 * @returns {boolean}
 */
function isUSMNeeded(transformsObj) {
    // ---------------------------------------------------------------------------------------
    // do not apply usm if transformed image width & height is same as source image or larger
    // and no force usm is desired
    // ---------------------------------------------------------------------------------------
    const transformPart = utils.last(transformsObj.parts)
    const upscale = transformPart.scaleFactor >= 1

    // return if usm is needed
    return !upscale || transformPart.forceUSM
}


/**
 * indicates if all usm values are presented and in range
 * @param {object}  usm     unsharp mask
 *
 * @returns {boolean}
 */
function isUSMValid(usm) {
    usm = usm || {}
    const radius = !isNaN(usm.radius) && typeof usm.radius === 'number' && (usm.radius >= 0.1 && usm.radius <= 500)
    const amount = !isNaN(usm.amount) && typeof usm.amount === 'number' && (usm.amount >= 0 && usm.amount <= 10)
    const threshold = !isNaN(usm.threshold) && typeof usm.threshold === 'number' && (usm.threshold >= 0 && usm.threshold <= 255)

    // return is a valid USM data
    return radius && amount && threshold
}

/**
 * indicates if all usm values are presented and are zero. an explicit request to not apply usm
 * @param {object}  usm     unsharp mask
 *
 * @returns {boolean}
 */
function isZeroUSM(usm) {
    usm = usm || {}
    return !isNaN(usm.radius) && typeof usm.radius === 'number' && usm.radius === 0 &&
        (!isNaN(usm.amount) && typeof usm.amount === 'number' && usm.amount === 0) &&
        (!isNaN(usm.threshold) && typeof usm.threshold === 'number' && usm.threshold === 0)
}

module.exports.setTransformOptions = setTransformOptions
