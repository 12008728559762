'use strict'

const constants = require('../../helpers/imageServiceConstants')
const {isValidRequest} = require('../../helpers/imageServiceUtils')
const attributes = require('../../engines/attributes')
const transform = require('../transform')
const {getURI} = require('../uri')
const {
    getScaledDimensions,
    getBlurValue,
    getCSSOverrides,
    validateTargetDimensions,
    getIsFakeTile,
    getConvertedFitting
} = require('../../helpers/imagePlaceholderUtils')

/**
 * returns image _placeholder_ transform uri and the
 * needed css for alignment and positioning
 *
 * @param {string}                  fittingType imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src         image
 * @param {ImageTransformTarget}    target      target dimensions
 * @param {ImagePlaceholderOptions} [options]   environment / transformation  options
 *
 * @returns {{uri: string, css: {img: {}, container: {}}} | {uri: string, attr: {img: {}, container: {}}} | {}}
 */
function getPlaceholder(fittingType, src, target, options = {}) {
    if (!isValidRequest(fittingType, src, target)) {
        return constants.emptyData
    }
    const newTarget = {...target, ...validateTargetDimensions(src, target)}
    const {alignment, htmlTag} = target
    const {isSEOBot} = options
    const isFakeTile = getIsFakeTile(fittingType, src, newTarget)

    const scaledDimensions = getScaledDimensions(fittingType, src, newTarget, isSEOBot)

    const blur = getBlurValue(newTarget.width, fittingType, isSEOBot)

    const convertedFittingType = getConvertedFitting(fittingType, isFakeTile)

    const overrideCSS = getCSSOverrides(fittingType, src, target, alignment)

    const {uri} = getData(
        convertedFittingType,
        src,
        {
            ...scaledDimensions,
            alignment,
            htmlTag
        },
        {
            filters: {blur}
        })
    const {attr = {}, css} = getData(
        fittingType,
        src,
        {
            ...newTarget,
            alignment,
            htmlTag
        })
    css.img = css.img || {}
    css.container = css.container || {}
    Object.assign(css.img, overrideCSS.img)
    Object.assign(css.container, overrideCSS.container)
    return {uri, css, attr}
}

/**
 * returns image transform uri and the
 * needed css for alignment and positioning
 *
 * @param {string}                  fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   [options]          transform options
 *
 * @returns {{uri: string, css: {img: {}, container: {}}} | {uri: string, attr: {img: {}, container: {}}} | {}}
 */
function getData(fittingType, src, target, options) {
    let data = {}

    // check if valid request
    if (isValidRequest(fittingType, src, target)) {
        // handle site BG legacy fitting types if needed
        const targetObj = transform.getTarget(fittingType, src, target)
        // parse request and create working OBJ
        const transformObj = transform.getTransform(fittingType, src, targetObj, options)

        data.uri = getURI(fittingType, src, targetObj, options, transformObj)
        //set the CSS or the SVG property
        Object.assign(data, attributes.getAttributes(transformObj, targetObj))
    } else {
        // empty data
        data = constants.emptyData
    }

    // return data to be used in html
    return data
}

module.exports.getData = getData
module.exports.getPlaceholder = getPlaceholder
