'use strict'

const {
    isSEOBot,
    getFileType,
    getFileName,
    getFileExtension,
    getDevicePixelRatio,
    getUpscaleString,
    isImageTransformApplicable
} = require('../helpers/imageServiceUtils')
const {isMobile, isWEBPBrowserSupport} = require('../helpers/browserFeatureSupport')
const transformParts = require('../helpers/imageTransformParts')
const transformOptions = require('../helpers/imageTransformOptions')
const constants = require('../helpers/imageServiceConstants')

/**
 * returns image transform data
 *
 * @param {string}                  fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   [options]           transform options
 *
 * @returns {Object}
 */
function getTransform(fittingType, src, target, options) {
    const _isSEOBot = isSEOBot(options)
    const fileType = getFileType(src.id)
    const fileName = getFileName(src.id, src.name)
    const fileExtension = getFileExtension(src.id)
    const isWEBPSupport = !_isSEOBot && isWEBPBrowserSupport(fileType)
    const devicePixelRatio = _isSEOBot ? 1 : getDevicePixelRatio(target)
    const preferredExtension = isWEBPSupport ? 'webp' : fileExtension

    const transformsObj = {
        fileName,
        fileExtension,
        fileType,
        isWEBPSupport,
        fittingType,
        preferredExtension,
        src: {
            id: src.id,
            width: src.width,
            height: src.height,
            isCropped: false
        },
        focalPoint: {
            x: src.focalPoint && src.focalPoint.x,
            y: src.focalPoint && src.focalPoint.y
        },
        parts: [],
        // options - general
        devicePixelRatio,
        quality: 0,
        upscaleMethod: getUpscaleString(options),
        progressive: true,
        watermark: '',
        unsharpMask: {},
        filters: {}
    }
    if (isImageTransformApplicable(src.id)) {
        transformParts.setTransformParts(transformsObj, src, target)
        transformOptions.setTransformOptions(transformsObj, options)
    }

    return transformsObj
}


/**
 * returns target data
 * handle legacy BG site if needed
 *
 * @param {string}                  fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 *
 * @returns {Object}
 */
function getTarget(fittingType, src, target) {
    const targetObj = {...target}
    const _isMobile = isMobile()

    // handle site BG legacy fitting types (desktop & mobile)
    switch (fittingType) {
        case constants.fittingTypes.LEGACY_BG_FIT_AND_TILE:
        case constants.fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL:
        case constants.fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL:
        case constants.fittingTypes.LEGACY_BG_NORMAL:

            const maxBGSiteLegacyWidth = _isMobile ? constants.MOBILE_MAX_BG_SITE_LEGACY_WIDTH : constants.DSKTP_MAX_BG_SITE_LEGACY_WIDTH
            const maxBGSiteLegacyHeight = _isMobile ? constants.MOBILE_MAX_BG_SITE_LEGACY_HEIGHT : constants.DSKTP_MAX_BG_SITE_LEGACY_HEIGHT
            targetObj.width = Math.min(maxBGSiteLegacyWidth, src.width)
            targetObj.height = Math.min(maxBGSiteLegacyHeight, Math.round(targetObj.width / (src.width / src.height)))
            // for legacy types force htmlTag='bg' and devicePixelRatio=1
            targetObj.pixelAspectRatio = 1
    }

    return targetObj
}



module.exports.getTransform = getTransform
module.exports.getTarget = getTarget

/**
 * the source image to transform
 * @typedef  {object} ImageTransformSource
 * @property {string}  id               source image uri
 * @property {number}  width            source image width
 * @property {number}  height           source image height
 * @property {string}  [name]           source image display name
 * @property {object}  [crop]           source image crop values
 * @property {number}  crop.x           crop x
 * @property {number}  crop.y           crop y
 * @property {number}  crop.width       crop width
 * @property {number}  crop.height      crop height
 * @property {object}  [focalPoint]     source image focal point values
 * @property {number}  focalPoint.x     focal point x
 * @property {number}  focalPoint.y     focal point y
 */

/**
 * the destination container
 * @typedef  {object}  ImageTransformTarget
 * @property {number}  width                destination container width
 * @property {number}  height               destination container height
 * @property {number}  [pixelAspectRatio]   for retina and mobile displays, 1 (default)
 * @property {string}  [alignment]          how to align the image in the container, imageService.alignTypes  CENTER(default)
 * @property {string}  [htmlTag]            the css style, imageService.htmlTag IMG(default)
 */

/**
 * the transform options
 * @typedef  {object}   ImageTransformOptions
 * @property {boolean}  [progressive]               image transform progressive
 * @property {string}   [upscaleMethod]             image upscale method
 * @property {number}   [quality]                   image transform quality
 * @property {string}   [watermark]                 image watermark id
 * @property {object}   [filters]                   filters
 * @property {object}   [unsharpMask]               unsharpMask filter
 * @property {number}   [unsharpMask.radius]        unsharpMask radius
 * @property {number}   [unsharpMask.amount]        unsharpMask amount
 * @property {number}   [unsharpMask.threshold]     unsharpMask threshold
 * @property {boolean}  [isSEOBot]                  indication if SEO bot
 * @property {string}   [preferredExtension]        file extension after transformation
 */


/**
 * the Image Placeholder options
 * @typedef  {object}   ImagePlaceholderOptions
 * @property {boolean}   [isSEOBot]             image upscale method
 */

/**
 * the transform results
 * @typedef  {object} ImageTransformResult
 * @property {string} uri the generated image uri, *without* base path
 * @property {object} css CSS properties for the image and it's parent container, empty object for SVG
 * @property {object} css.img CSS for the image element
 * @property {object} css.container CSS for the image container
 * @property {object} attr HTML Attributes for the image and it's parent container, empty object for bg and img types
 * @property {object} attr.img HTML Attributes for the image element
 * @property {object} attr.container HTML Attributes for the image container
 *
 */
