'use strict'
const {noWEBP} = require('../helpers/imageServiceConstants')
const browserDetect = require('wix-browser-detection')
const WEBPDetection = require('./webPDetection')
const globalFeatureSupportObject = require('./imageServiceFeatureSupportObject')

/**
 * Populate the global feature support object with browser specific values
 */
function populateGlobalFeatureSupport(userAgent = '') {
    let browserDetection
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
        browserDetection = browserDetect(navigator.userAgent)
        if (browserDetection.browser.safari || browserDetection.os.iphone || browserDetection.os.ipad) {
            globalFeatureSupportObject.setFeature('isWEBP', noWEBP)
        } else {
            WEBPDetection.checkSupportByUserAgent(navigator.userAgent, browserDetection)
            WEBPDetection.checkSupportByFeatureDetection()
        }
        // objectfit support
        globalFeatureSupportObject.setFeature('isObjectFitBrowser', isPropertySupported('objectFit'))
        // set is mobile
        globalFeatureSupportObject.setFeature('isMobile', browserDetection.os.phone)
    } else {
        browserDetection = browserDetect(userAgent)
        WEBPDetection.checkSupportByUserAgent(userAgent, browserDetection)
        // set is mobile
        globalFeatureSupportObject.setFeature('isMobile', browserDetection.os.phone)
    }
}

/**
 * returns if a css property is supported
 * @param property
 *
 * @returns {boolean}
 */
function isPropertySupported(property) {
    return property in window.document.documentElement.style
}

module.exports.populateGlobalFeatureSupport = populateGlobalFeatureSupport
