'use strict'

const {alignTypes, fittingTypes} = require('./imageServiceConstants')

const ALIGN_TYPE_TO_POSITION = {
    [alignTypes.CENTER]: '50% 50%',
    [alignTypes.TOP_LEFT]: '0% 0%',
    [alignTypes.TOP_RIGHT]: '100% 0%',
    [alignTypes.TOP]: '50% 0%',
    [alignTypes.BOTTOM_LEFT]: '0% 100%',
    [alignTypes.BOTTOM_RIGHT]: '100% 100%',
    [alignTypes.BOTTOM]: '50% 100%',
    [alignTypes.RIGHT]: '100% 50%',
    [alignTypes.LEFT]: '0% 50%'
}

const POSITION_TO_ALIGN_TYPE = Object.entries(ALIGN_TYPE_TO_POSITION)
    .reduce((acc, [align, position]) => {
        acc[position] = align
        return acc
    }, {})


const TILE_FITTING_TYPES = [
    fittingTypes.TILE,
    fittingTypes.TILE_HORIZONTAL,
    fittingTypes.TILE_VERTICAL,
    fittingTypes.LEGACY_BG_FIT_AND_TILE,
    fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL,
    fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL
]

const NON_SCALING_FITTING_TYPES = [
    fittingTypes.LEGACY_ORIGINAL_SIZE,
    fittingTypes.ORIGINAL_SIZE,
    fittingTypes.LEGACY_BG_NORMAL
]

function getIsFakeTile(fittingType, src, {width: targetWidth, height: targetHeight}) {
    return fittingType === fittingTypes.TILE && src.width > targetWidth && src.height > targetHeight
}
/**
 *
 * @param {string}                  fittingType      imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src              source image
 * @param {Dimensions}              target           target container dimensions
 * @param {boolean}                 [isSEOBot=false] whether it is a render for a bot request
 * @returns {Dimensions} scaled dimensions
 */
function getScaledDimensions(fittingType, src, {width, height}, isSEOBot = false) {
    if (isSEOBot) {
        return {width, height}
    }

    const isScalable = !NON_SCALING_FITTING_TYPES.includes(fittingType)
    const isFakeTile = getIsFakeTile(fittingType, src, {width, height})
    const isTile = !isFakeTile && TILE_FITTING_TYPES.includes(fittingType)
    const _width = isTile ? src.width : width
    const _height = isTile ? src.height : height
    const scaleFactor = isScalable ? getScaleFactorByWidth(_width) : 1

    return {
        width: isFakeTile ? 1920 : _width * scaleFactor,
        height: _height * scaleFactor
    }
}

/**
 *
 * @param {string} fittingType  imageServicesTypes.fittingTypes
 * @param {boolean} isFakeTile
 * @returns {string} fittingType
 */
function getConvertedFitting(fittingType, isFakeTile) {
    const isTile = TILE_FITTING_TYPES.includes(fittingType) && !isFakeTile
    const isFill = fittingType === fittingTypes.SCALE_TO_FILL
    return isFill || isTile ?
        fittingTypes.SCALE_TO_FIT :
        fittingType
}

/**
 *
 * @param {ImageTransformSource}    src                 source image
 * @param {number || ''}            width
 * @param {number || ''}            height
 * @returns {{number}, {number}}    width, height
 */
function validateTargetDimensions(src, {width, height}) {
    if (!width || !height) {
        const _width = width || Math.min(980, src.width)
        const heightRatio = _width / src.width
        return {
            width: _width,
            height: height || src.height * heightRatio
        }
    }
    return {width, height}
}

/**
 *
 * @param {number} width
 * @return {number}
 */
function getScaleFactorByWidth(width) {
    if (width > 900) {
        return 0.25
    } else if (width > 500) {
        return 0.3
    } else if (width > 200) {
        return 0.4
    }

    return 1
}

/**
 *
 * @param {number} width
 * @param {string} fittingType imageServicesTypes.fittingTypes
 * @param {boolean} isSEOBot
 * @return {number}
 */
function getBlurValue(width, fittingType, isSEOBot) {
    if (isSEOBot) {
        return 0
    }
    if (TILE_FITTING_TYPES.includes(fittingType)) {
        return 1
    }

    if (width > 200) {
        return 2
    }

    return 3
}

/**
 *
 * @param {string}               fittingType
 * @param {ImageTransformSource} src                  source image
 * @param {Dimensions}           target               target element
 * @param {string}               [alignment='center']
 * @returns {{img}, {container}}
 */
function getCSSOverrides(fittingType, src, target, alignment = 'center') {
    const returnValue = {
        img: {},
        container: {}
    }
    if (fittingType === fittingTypes.SCALE_TO_FILL) {
        const alignTypeFromFocalPoint = src.focalPoint && convertFocalPointToAlignType(src.focalPoint)
        const alignType = alignTypeFromFocalPoint || alignment

        if (src.focalPoint && !alignTypeFromFocalPoint) {
            returnValue.img = {
                objectPosition: convertFillFocalToPosition(src, target, src.focalPoint)
            }
        } else {
            returnValue.img = {
                objectPosition: ALIGN_TYPE_TO_POSITION[alignType]
            }
        }
    } else if ([fittingTypes.LEGACY_ORIGINAL_SIZE, fittingTypes.ORIGINAL_SIZE].includes(fittingType)) {
        returnValue.img = {
            objectFit: 'none',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto'
        }
    } else if (TILE_FITTING_TYPES.includes(fittingType)) {
        returnValue.container = {
            backgroundSize: `${src.width}px ${src.height}px`
        }
    }

    return returnValue
}



/**
 * Try to convert focal point value to 9 grid alignment value
 *
 * @param {Point}    focalPoint
 * @returns {string} align type
 */
function convertFocalPointToAlignType(focalPoint) {
    const position = `${focalPoint.x}% ${focalPoint.y}%`

    return POSITION_TO_ALIGN_TYPE[position] || ''
}

/**
 *
 * @param {Dimensions} src source dimensions
 * @param {Dimensions} target target dimensions
 * @param {Point} focalPoint x/y as 0-100 percentages
 * @returns {string} in 'x% y%' format
 */
function convertFillFocalToPosition(src, target, focalPoint) {
    const {width: sW, height: sH} = src
    const {width: tW, height: tH} = target
    const {x: fpX, y: fpY} = focalPoint

    if (!tW || !tH) {
        return `${fpX}% ${fpY}%`
    }

    const fillScaleFactor = Math.max(tW / sW, tH / sH)

    const imgScaledW = sW * fillScaleFactor
    const imgScaledH = sH * fillScaleFactor

    const x = Math.max(0, Math.min(imgScaledW - tW, imgScaledW * (fpX / 100) - tW / 2))
    const y = Math.max(0, Math.min(imgScaledH - tH, imgScaledH * (fpY / 100) - tH / 2))

    const posX = x && Math.floor(x / (imgScaledW - tW) * 100)
    const posY = y && Math.floor(y / (imgScaledH - tH) * 100)

    return `${posX}% ${posY}%`
}

module.exports.validateTargetDimensions = validateTargetDimensions
module.exports.getScaledDimensions = getScaledDimensions
module.exports.getConvertedFitting = getConvertedFitting
module.exports.getIsFakeTile = getIsFakeTile
module.exports.getBlurValue = getBlurValue
module.exports.getCSSOverrides = getCSSOverrides

/**
 * @typedef Dimensions
 * @property {number} width
 * @property {number} height
 *
 * @typedef Point
 * @property {number} x
 * @property {number} y
 */
