'use strict'

const WEBPDetection = require('./webPDetection')
const globalFeatureSupportObject = require('./imageServiceFeatureSupportObject')

/**
 * check if the browser supports ObjectFit css attribute
 *
 * @returns {boolean}
 */
function isObjectFitBrowserSupport() {
    return globalFeatureSupportObject.getFeature('isObjectFitBrowser')
}

/**
 * get a browser detection if running on mobile device
 *
 * @returns {boolean}
 */
function isMobile() {
    return globalFeatureSupportObject.getFeature('isMobile')
}

module.exports.isWEBPBrowserSupport = WEBPDetection.isWEBPBrowserSupport
module.exports.isObjectFitBrowserSupport = isObjectFitBrowserSupport
module.exports.isMobile = isMobile
